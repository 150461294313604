<template>
	<v-text-field
		:class="light ? 'search-bar light' : 'search-bar dark'"
		:label="$t('searchbar.search') + ' ' + label"
		v-model="filter"
		solo
		prepend-inner-icon="far fa-search"
		flat
	></v-text-field>
</template>

<script>
import { search } from '@/api/common.js';

export default {
	name: 'SearchBar',
	props: {
		label: {
			type: String,
			required: true
		},
		model: {
			type: String,
			required: true
		},
		value: {
			type: [Object, Array]
		},
		customFilter: {
			type: [Object, Array],
			default: null,
			required: false
		},
		light: Boolean
	},
	data() {
		return {
			filter: '',
			filteredResults: []
		};
	},
	methods: {
		emitValue() {
			this.$emit('input', this.filteredResults);
		},

		async doSearch() {
			this.$puiEvents.$emit(`searching-true_${this.model}`, true);
			const body = {
				filter: {
					groupOp: 'and',
					groups: this.customFilter ? this.customFilter : [],
					rules: [{ data: this.$store.state.eventInfo.evenid, field: 'event', op: 'eq' }]
				},
				model: this.model,
				order: [
					{
						column: 'name',
						direction: 'asc'
					}
				],
				page: 1,
				queryFields: ['name'],
				queryText: this.filter,
				rows: 999999999
			};

			const data = await search(body);
			this.filteredResults = data.data;
			this.$puiEvents.$emit(`searching-false_${this.model}`, false);
			this.emitValue();
		},

		searchTimeOut() {
			// clear timeout variable
			clearTimeout(this.timeout);

			var _this = this;
			this.timeout = setTimeout(function () {
				_this.doSearch();
			}, 400);
		}
	},
	computed: {
		primaryColor: function () {
			return this.$store.state.eventInfo.evenappprimarycolor
				? this.$store.state.eventInfo.evenappprimarycolor
				: this.$store.state.eventInfo.defaultColor;
		}
	},
	watch: {
		filter(newVal) {
			if (newVal || newVal === '') {
				this.searchTimeOut();
			}
		}
	}
};
</script>

<style lang="scss">
.search-bar {
	& > .v-input__control > .v-input__slot > .v-text-field__slot {
		width: 21.25rem;
		& > label {
			height: 100%;
			padding-top: 0.5rem;
			font-family: Cabin;
			font-size: 15px;
			padding-left: 0.5rem;
		}

		& > input {
			font-family: Cabin;
			font-size: 15px;
			padding-top: 0.125rem;
		}
	}

	& > .v-input__control > .v-input__slot > .v-input__prepend-inner > .v-input__icon > .v-icon {
		font-size: 0.75rem !important;
	}

	& > .v-input__control > .v-input__slot {
		border-radius: 8px;
	}
}

.search-bar.dark {
	& > .v-input__control > .v-input__slot > .v-text-field__slot {
		& > label {
			color: rgba(255, 255, 255, 0.67) !important;
		}
		& > input {
			color: #fff;
		}
	}

	& > .v-input__control > .v-input__slot > .v-input__prepend-inner > .v-input__icon > .v-icon {
		color: #fff !important;
	}

	& > .v-input__control > .v-input__slot {
		background-color: rgba(0, 0, 0, 0.16) !important;
	}
}

.search-bar.light {
	& > .v-input__control > .v-input__slot > .v-text-field__slot {
		& > label {
			color: rgba(87, 94, 96, 0.67) !important;
		}
		& > input {
			color: #293133;
		}
	}

	& > .v-input__control > .v-input__slot > .v-input__prepend-inner > .v-input__icon > .v-icon {
		color: #293133 !important;
	}

	& > .v-input__control > .v-input__slot {
		background-color: #f3f5f5 !important;
	}
}
</style>


