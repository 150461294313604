<template>
	<v-expansion-panel>
		<v-expansion-panel-header expand-icon="fal fa-plus">
			{{ $t('filters.sectorTitle') }}
			<div v-if="selectedSectors.length" style="padding-left: 0.5rem">
				<span class="sector-filter__num-selecteds">{{ selectedSectors.length }}</span>
			</div>
		</v-expansion-panel-header>
		<v-expansion-panel-content>
			<div v-for="sector in selectedSectors" :key="sector" class="sector-filter__selected" style="margin-bottom: 10px">
				<i class="fal fa-plus" style="cursor: pointer" @click="removeSector(sector)"></i>
				<span class="sector-filter__selected-name">{{ sector }}</span>
			</div>
			<div class="sector-filter__bottom">
				<v-dialog v-model="dialog" width="676">
					<template v-slot:activator="{ on, attrs }">
						<v-btn class="sector-filter__bottom-button" depressed v-bind="attrs" v-on="on">
							{{ $t('filters.sectorButton') }}
						</v-btn>
					</template>

					<v-card>
						<div style="position: relative">
							<div style="position: absolute; right: 0; margin-right: 1rem; margin-top: 1rem">
								<img :src="IconClose" alt="close" @click="dialog = false" style="cursor: pointer" />
							</div>
						</div>
						<v-card-title class="sector-filter__modal-title"> {{ $t('filters.sectorModalTitle') }} </v-card-title>

						<v-card-text style="padding: 0">
							<div class="sector-filter__modal-search">
								<div style="padding: 0 2.5rem; width: 100%">
									<SearchBar
										:label="$t('filters.modalSearchLabel')"
										model="catalogsector"
										v-model="sectors"
										light
										style="width: 100%"
										:key="refreshSearchbar"
									/>
								</div>
							</div>
							<div class="sector-filter__modal-body">
								<v-expansion-panels class="condensed" accordion flat multiple>
									<v-expansion-panel :readonly="item.subsectors ? false : true" v-for="(item, index) in sectors" :key="item.sector">
										<v-expansion-panel-header>
											<template v-slot:actions>
												<v-icon :class="item.subsectors ? 'icon' : 'icon icon-disabled'">$expand</v-icon>
											</template>
											<div class="header">
												<v-checkbox
													:style="item.subsectors ? '' : 'padding-left: 34.5px'"
													:label="item.name"
													:id="`checkbox_${index}`"
													:value="ischecked(item.name)"
													v-model="item.checked"
													@change="isSelected(item.name)"
												></v-checkbox>
											</div>
										</v-expansion-panel-header>
										<v-expansion-panel-content v-if="item.subsectors">
											<v-checkbox
												v-for="subsector in item.subsectors"
												:key="subsector.id"
												:label="`${subsector.name}`"
												v-model="subsector.checked"
												style="padding-left: 3.5rem"
												@change="isSelected(subsector.name)"
											></v-checkbox>
										</v-expansion-panel-content>
									</v-expansion-panel>
								</v-expansion-panels>
							</div>
						</v-card-text>
					</v-card>
				</v-dialog>
			</div>
		</v-expansion-panel-content>
	</v-expansion-panel>
</template>

<script>
import SearchBar from '@/components/common/SearchBar.vue';
import { search } from '@/api/common.js';

export default {
	name: 'SectorFilter',
	components: { SearchBar },

	data() {
		return {
			IconClose: require('@/assets/icons/close.svg'),
			dialog: false,
			sectors: {},
			refreshSearchbar: 0,
			selectedSectors: []
		};
	},

	mounted() {
		this.getSectorsFromQuery();
	},

	methods: {
		getSectors: async function () {
			const body = {
				filter: {
					groupOp: 'and',
					groups: [],
					rules: [
						{
							data: this.$store.state.eventInfo.evenid,
							field: 'event',
							op: 'eq'
						},
						{
							data: null,
							field: 'parent',
							op: 'nu'
						}
					]
				},
				model: 'catalogsector',
				order: [
					{
						column: 'name',
						direction: 'asc'
					}
				],
				page: 1,
				queryFields: ['name'],
				queryText: '',
				rows: 999999999
			};

			let data = await search(body);
			this.sectors = data.data;
			this.sectors.forEach((sector) => {
				sector.subsectors = JSON.parse(sector.subsectorsjson);

				this.selectedSectors.forEach((selectedSector) => {
					if (sector.name === selectedSector) {
						sector.checked = true;
					}
					if (sector.subsectors) {
						sector.subsectors.forEach((subsector) => {
							if (selectedSector === subsector.name) subsector.checked = true;
						});
					}
				});
				sector.name = sector.name.replace(/&amp;/g, '&').replace(/&lt;/g, '<').replace(/&gt;/g, '>');
			});
		},

		addSector: function (sectorname) {
			this.selectedSectors.push(sectorname);
			const query = Object.assign({}, this.$route.query);
			delete query.sector;
			this.$router.replace({ query: { sector: this.selectedSectors } });
		},

		removeSector: function (sectorname) {
			const index = this.selectedSectors.indexOf(sectorname);
			if (index > -1) {
				this.selectedSectors.splice(index, 1);
			}
			const query = Object.assign({}, this.$route.query);
			delete query.sector;
			this.$router.replace({ query });
			this.$router.push({ query: { sector: this.selectedSectors } });
		},

		isSelected(sectorname) {
			let isSelected = false;
			this.selectedSectors.forEach((element) => {
				if (element === sectorname) {
					isSelected = true;
				}
			});

			isSelected ? this.removeSector(sectorname) : this.addSector(sectorname);
		},
		ischecked(sectorname) {
			let isSelected = false;
			this.selectedSectors.forEach((element) => {
				if (element === sectorname) {
					isSelected = true;
				}
			});
			return isSelected;
		},

		getSectorsFromQuery: function () {
			if (this.$route.query.sector) {
				const data = this.$route.query.sector;
				if (Array.isArray(data)) this.selectedSectors = data;
				else this.selectedSectors.push(data);
			}
		}
	},

	watch: {
		dialog(newVal) {
			if (newVal) {
				this.refreshSearchbar++;
				this.getSectors();
			}
		},

		'$route.query': function () {
			if (!this.$route.query.sector) this.selectedSectors = [];
		}
	}
};
</script>


<style lang="scss" scoped>
.icon-disabled {
	display: none;
}
.fal.fa-plus {
	transform: rotate(45deg);
	font-size: 1.125rem;
	color: #575e60;
}

.icon {
	order: 0;
	padding: 0 0.75rem;
	font-size: 0.75rem;
}

.header {
	order: 1;
}

.v-expansion-panels.condensed .v-expansion-panel-header {
	padding-top: 2px;
	padding-bottom: 2px;
	min-height: auto;
}
.v-expansion-panels.condensed .v-expansion-panel--active .v-expansion-panel-header {
	padding-top: 8px;
	padding-bottom: 8px;
}
.v-expansion-panels.condensed .v-expansion-panel--active:not(:first-child),
.v-expansion-panels.condensed .v-expansion-panel--active + .v-expansion-panel {
	margin-top: 4px;
}

.sector-filter {
	&__num-selecteds {
		background-color: #2498ff;
		border-radius: 0.8em;
		-moz-border-radius: 0.8em;
		-webkit-border-radius: 0.8em;
		color: #fff;
		display: inline-block;
		line-height: 1rem;
		text-align: center;
		width: 1rem;
		font-size: 0.75rem;
	}

	&__selected {
		display: flex;
		align-items: center;

		&-name {
			font-size: 14px;
			color: #575e60;
			padding-left: 0.25rem;
		}
	}

	&__bottom {
		display: flex;
		align-items: center;
		justify-content: center;
		padding-top: 1rem;

		&-button {
			background-color: #fff !important;
			border: 1px solid #e3e7e8;
			border-radius: 8px !important;

			font-family: Cabin;
			font-weight: bold;
			font-size: 16px;
			color: #575e60;
			padding: 1 25rem 1rem !important;
		}
	}

	&__modal {
		&-title {
			padding-top: 2.25rem !important;
			padding-left: 2.5rem !important;
			font-family: Cabin;
			font-weight: bold !important;
			font-size: 26px !important;
			color: #293133;
		}

		&-search {
			display: flex;
			justify-content: center;
			box-shadow: 0px 8px 8px -8px rgb(0 0 0 / 18%);
		}

		&-body {
			padding: 0.5rem 2.5rem;
		}

		&-button {
			padding-right: 2.5rem !important;
			padding-bottom: 1.5rem !important;
			display: flex;
			justify-content: flex-end;
		}
	}
}

.v-expansion-panel-header>:not(.v-expansion-panel-header__icon) {
    flex: 1 1 auto;
}
</style>
