<template>
	<v-expansion-panel>
		<v-expansion-panel-header expand-icon="fal fa-plus">
			{{ $t('filters.countryTitle') }}
			<div v-if="selectedCountries.length" style="padding-left: 0.5rem">
				<span class="country-filter__num-selecteds">{{ selectedCountries.length }}</span>
			</div>
		</v-expansion-panel-header>
		<v-expansion-panel-content>
			<v-checkbox
				v-for="(item, i) in countries"
				:key="i"
				:label="item.name"
				:value="ischecked(item.name)"
				v-model="item.checked"
				@change="isSelected(item.name)"
			></v-checkbox>
		</v-expansion-panel-content>
	</v-expansion-panel>
</template>

<script>
import { search } from '@/api/common.js';

export default {
	name: 'CountryFilter',

	data() {
		return {
			countries: null,
			selectedCountries: []
		};
	},

	components: {},

	mounted() {
		this.getInfo();
		this.getCountriesFromQuery();
	},

	methods: {
		getInfo: async function () {
			const body = {
				filter: {
					groupOp: 'and',
					groups: [],
					rules: [
						{
							data: this.$store.state.eventInfo.evenid,
							field: 'event',
							op: 'eq'
						}
					]
				},
				model: 'catalogcountry',
				order: [
					{
						column: 'name',
						direction: 'asc'
					}
				],
				page: 1,
				queryFields: ['name'],
				queryText: '',
				rows: 999999999
			};
			const data = await search(body);
			this.countries = data.data;

			this.countries.forEach((country) => {
				this.selectedCountries.forEach((selectedCountry) => {
					if (country.name === selectedCountry) {
						country.checked = true;
					}
				});
			});
		},

		addCountry: function (country) {
			this.selectedCountries.push(country);
			const query = Object.assign({}, this.$route.query);
			delete query.country;
			this.$router.replace({ query: { country: this.selectedCountries } });
		},

		removeCountry: function (country) {
			const index = this.selectedCountries.indexOf(country);
			if (index > -1) {
				this.selectedCountries.splice(index, 1);
			}
			const query = Object.assign({}, this.$route.query);
			delete query.country;
			this.$router.replace({ query });
			this.$router.push({ query: { country: this.selectedCountries } });
		},

		isSelected(country) {
			let isSelected = false;
			this.selectedCountries.forEach((element) => {
				if (element === country) {
					isSelected = true;
				}
			});

			isSelected ? this.removeCountry(country) : this.addCountry(country);
		},
		ischecked(country) {
			let isSelected = false;
			this.selectedCountries.forEach((element) => {
				if (element === country) {
					isSelected = true;
				}
			});
			return isSelected;
		},
		getCountriesFromQuery: function () {
			if (this.$route.query.country) {
				const data = this.$route.query.country;
				if (Array.isArray(data)) this.selectedCountries = data;
				else this.selectedCountries.push(data);
			}
		}
	},

	watch: {
		'$route.query': function () {
			if (!this.$route.query.country) {
				this.selectedCountries = [];
				this.getInfo();
			}
		}
	}
};
</script>


<style lang="scss" scoped>
.country-filter {
	&__num-selecteds {
		background-color: #2498ff;
		border-radius: 0.8em;
		-moz-border-radius: 0.8em;
		-webkit-border-radius: 0.8em;
		color: #fff;
		display: inline-block;
		line-height: 1rem;
		text-align: center;
		width: 1rem;
		font-size: 0.75rem;
	}
}
</style>
